import { GuestLayout } from '@esg-monitor/components';
import { Outlet } from '@remix-run/react';

export default function Index() {
  return (
    <GuestLayout>
      <Outlet />
    </GuestLayout>
  );
}
